import React from 'react';
import './_header.scss'
import {useTranslation} from "react-i18next";

export default function Header({width}) {
    const { t } = useTranslation();

    return(
        <div className={'headerComponent'}>
            <p className={'tradeMark'}><span style={{fontWeight: '200', fontSize: 30, marginRight: 10}}>OPUS</span> • 3D Rendering</p>

            {width > 800 &&
                <a href="https://buy.stripe.com/6oEeUX0ZS9zh4G4144" target={'_blank'} style={{textDecoration: 'none'}}>
                    <div className={'buttonOrderFloorPlan'}>
                        {t('orderMy3DFloorPlan')}
                    </div>
                </a>
            }
        </div>
    )
}

