import React from 'react';
import './_whatCouldYouExpect.scss'
import moreVisibility from '../../assets/moreVisibility.png'
import moreTrust from '../../assets/moreTrust.png'
import betterReviews from '../../assets/betterReviews.png'
import competitiveAdvantage from '../../assets/competitiveAdvantage.png'
import {useTranslation} from "react-i18next";

export default function WhatCouldYouExpect({width}) {
    const { t } = useTranslation();

    return(
        <div style={{backgroundColor: '#F8F8F8', borderRadius: width > 1200 && 15}}>
            <div className={'whatCouldYouExpect containerLargeComponent'}>
                <h2 className={'titlePart'}>{t('whatCouldYouExpect')}</h2>

                <div className={'containerImageTextPoint'}>
                    <ImageTextComponent title={t('moreVisibility')}
                                        text={t('moreVisibilityText')} image={moreVisibility}/>

                    <ImageTextComponent title={t('moreTrust')}
                                        text={t('moreTrustText')} image={moreTrust}/>

                    <ImageTextComponent title={t('betterReview')}
                                        text={t('betterReviewText')} image={betterReviews}/>

                    <ImageTextComponent title={t('aComparativeAdvantage')}
                                        text={t('aComparativeAdvantageText')} image={competitiveAdvantage}/>
                </div>
            </div>


        </div>

    )
}

function ImageTextComponent({title, text, image}) {

    return (
        <div className={'imageTextComponent'}>
            <img src={image} alt="" className={'imageTextComponentImg'} loading="lazy"/>
            <h3 className={'imageTextComponentTitle'}>{title}</h3>
            <p className={'imageTextComponentText'}>{text}</p>
        </div>
    )
}
