import React from 'react';
import {BrowserRouter as Router, Route, Switch} from "react-router-dom";
import HomePage from "./pages/Home Page/HomePage";

function App() {
    return (
        <Router>
            <Switch>
                <Route path="/" exact component={HomePage}/>

                {/* ☝️ TODO #6 Create the pages you need in the project */}

            </Switch>
        </Router>
    );
}

export default App;
