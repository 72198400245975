import React from "react";
import './_whichOneWouldYouChoose.scss'
import goodAnnonce from "../../assets/goodAnnonce.png"
import badAnnonce from "../../assets/badAnnonce.png"
import Separator from "../Separator";
import ButtonCallToAction from "../ButtonCallToAction";
import {useTranslation} from "react-i18next";

export default function WhichOneWouldYouChoose() {

    const { t } = useTranslation();

    return(
        <div className={'whichOneWouldYouChoose containerLargeComponent'}>
            <h2 className={'titlePart'}>{t('whichOneWouldYouChoose')}</h2>
            <p className={'subtitlePart'}>{t('contextDubai')}</p>


            <div className={'containerAnnonces'}>
                <img src={badAnnonce} alt="" className={'annonce'} loading="lazy"/>
                <p className={'vsComponent'}>VS</p>

                <img src={goodAnnonce} alt="" className={'annonce'} loading="lazy"/>
            </div>

            <Separator height={50}/>

            <ButtonCallToAction/>
            <Separator height={100}/>

        </div>
    )
}
