import React from 'react';
import './_threeStepsApproach.scss'
import firstStep from '../../assets/firstStep.png'
import secondStep from '../../assets/secondStep.png'
import thirdStep from '../../assets/thirdStep.png'
import Separator from "../Separator";
import ButtonCallToAction from "../ButtonCallToAction";
import {useTranslation} from "react-i18next";

export default function ThreeSteps({width}) {
    const { t } = useTranslation();

    return (
        <div style={{backgroundColor: '#F8F8F8', borderRadius: width > 1200 && 15}}>
            <div className={'threeStepsApproach containerLargeComponent'}>
                <h2 className={'titlePart'}>{t('threeStepsApproach')}</h2>
                <p className={'subtitlePart'}>{t('discoverOurEasyMethod')}</p>

                <Separator height={40}/>

                <StepComponent step={t('firstStep')} title={t('sendUsTheLinkOfYourAirbnb')}
                               text={t('sendUsTheLinkOfYourAirbnbText')} image={firstStep}/>
                <Separator height={width < 730 ? 100 : 40}/>
                <StepComponent inverted={true} step={t('secondStep')} title={t('ourTeamDesign')}
                               text={''} image={secondStep}/>
                <Separator height={width < 730 ? 100 : 40}/>

                <StepComponent step={'THIRD STEP'} title={t('uploadThe3DPlanToYourAirbnb')}
                               text={t('uploadThe3DPlanToYourAirbnbText')} image={thirdStep}/>
                <Separator height={width < 730 ? 100 : 40}/>

                <div className={'dashedBar'}/>

                <h2 className={'titlePart'}>{t('whyStartNow')}</h2>
                <p className={'subtitlePart'}>{t('afterHelping')}</p>

                <ButtonCallToAction/>

                <Separator height={100}/>

            </div>
        </div>

    )
}

function StepComponent({step, text, image, title, inverted}) {

    return (

        <div className={'stepComponent'} style={{flexDirection: inverted && 'row-reverse'}}>

            <div className={'textPart'}>
                <p className={'tagStep'}>
                    {step}
                </p>
                <h3 className={'titleStep'}> {title}</h3>
                <p className={'textStep'}>{text}</p>
            </div>

            <img src={image} alt="" className={'imageThreeSteps'} loading="lazy"/>
        </div>
    )
}
