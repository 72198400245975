import React from "react";
import './_whatsAppFlotant.scss'
import waWhite from '../../assets/waWhite.png'
import {useTranslation} from "react-i18next";

export default function WhatsAppFlotant({width}) {

    const { t } = useTranslation();

    return (
        <a href="https://wa.me/message/MBSVVPTMWE4UM1" style={{textDecoration: 'none'}} target={'_blank'}>
            <div className={'whatsAppFlotant'}>
                <img src={waWhite} alt="" className={'waBSimpleIcon'}/>
                {t('contactUsOnWhatsApp')}
            </div>
        </a>


    )
}
