import React from 'react';
import {useTranslation} from "react-i18next";

export default function ButtonCallToAction() {

    const { t } = useTranslation();

    return (
        <a href="https://buy.stripe.com/6oEeUX0ZS9zh4G4144" style={{display: 'flex', alignItems: 'center', flexDirection: 'column', textDecoration: 'none'}}>
            <button className={'callToAction'}>{t('orderPlanNow')}</button>
            <p className={'subtitleCallToAction'} >{t('yourBluePrintForOnly')}</p>
        </a>

    )
}
