import React from 'react'
import './_questions.scss'
import Separator from "../Separator";
import waB from "../../assets/waB.png";
import mail from "../../assets/mail.jpg";
import {useTranslation} from "react-i18next";

export default function Questions() {
    const { t } = useTranslation();

    return(
        <div className={'questions containerLargeComponent'}>
            <h2 className={'titlePart'}>{t('questionYouMightHave')}</h2>

            <div className={'containerQuestions'}>
                <QuestionItem question={t('question1')} answer={t('answer1')}/>
                <QuestionItem question={t('question2')} answer={t('answer2')}/>
                <QuestionItem question={t('question3')} answer={t('answer3')}/>
                <QuestionItem question={t('question4')} answer={t('answer4')}/>
                <QuestionItem contact={true} question={t('question5')} answer={t('answer5')}/>
            </div>

            <Separator height={100}/>
        </div>
    )
}

function QuestionItem({question, answer, contact}) {

    return(
        <div className={'questionItem'}>
            <p className={'questionText'}>{question}</p>
            <p className={'answerText'}>{answer}</p>

            {contact &&
                <div style={{display: 'flex', flexDirection: 'column', alignItems: 'flex-start', gap: 15, marginTop: 20}}>
                    <ContactInfo path={'https://wa.me/message/MBSVVPTMWE4UM1'} icon={waB} text={'+971 58 5559 6121'} backgroundColor={'rgba(44,185,39,0.1)'} color={'#2CB927'}/>
                    <ContactInfo path={'mailto:team@opusoft-render.com'}  icon={mail} text={'team@opusoft-render.com'} backgroundColor={'rgba(28,87,221,0.1)'} color={'#1C57DD'}/>
                </div>
            }
        </div>
    )
}

function ContactInfo({icon, text, color, backgroundColor, path}) {

    return(
        <a href={path} className={'contactInfo'} style={{color: color, backgroundColor: backgroundColor}} target={'_blank'}>
            <img src={icon} alt="" className={'iconContactInfo'}/>
            {text}
        </a>
    )
}
