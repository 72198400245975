import React, {useEffect, useState} from "react";
import './_homePage.scss'
import Header from "../../components/Header/Header";
import LandingPart from "../../components/Landing Part/LandingPart";
import WhatCouldYouExpect from "../../components/What Could You Expect/WhatCouldYouExpect";
import Separator from "../../components/Separator";
import WhichOneWouldYouChoose from "../../components/Which One Would You Choose/WhichOneWouldYouChoose";
import ThreeSteps from "../../components/Three Steps/ThreeSteps";
import ASuccessfulApproach from "../../components/A Successful Approach/ASuccessfulApproach";
import Questions from "../../components/Questions/Questions";
import LastCallToAction from "../../components/Last Call To Action/LastCallToAction";
import WhatsAppFlotant from "../../components/WhatsApp Flotant/WhatsAppFlotant";

export default function HomePage() {

    const [width, setWidth] = useState(800);

    useEffect(() => {
        window.addEventListener("resize", Update);
        Update();
        return () => window.removeEventListener("resize", Update);
    }, []);

    function Update() {
        setWidth(window.innerWidth);
    }

    return (
        <div className={'homePage'}>
            <div style={{zIndex: 100}}>
                <WhatsAppFlotant/>

            </div>

            <Header width={width}/>

            <LandingPart/>

            <Separator height={100}/>

            <WhatCouldYouExpect width={width}/>

            <WhichOneWouldYouChoose/>

            {/*<ANewMethod/>*/}

            <ThreeSteps width={width}/>

            {/*<UniquePrice/>*/}

            <ASuccessfulApproach width={width}/>

            <Questions/>

                <LastCallToAction width={width}/>


        </div>
    );
}
