import React from 'react';
import reviewOne from '../../assets/reviewOne.png'
import reviewOneData from '../../assets/reviewOneData.png'
import reviewTwo from '../../assets/reviewTwo.png'
import reviewTwoData from '../../assets/reviewTwoData.png'
import './_aSuccessfulApproach.scss'
import Separator from "../Separator";
import people from "../../assets/people.png";
import ButtonCallToAction from "../ButtonCallToAction";
import {useTranslation} from "react-i18next";

export default function ASuccessfulApproach({width}) {
    const { t } = useTranslation();

    return (
            <div className={'aSuccessfulApproach containerLargeComponent'}>
                <h2 className={'titlePart'} style={{width: 500}}>{t('aUniqueApproach')}</h2>
                <p className={'subtitlePart'}>A bit more about our clients</p>

                <div className={'containerAvis'}>

                    <div style={{display: 'flex', flexDirection: 'column', gap: 10}}>
                        <img src={reviewOne} alt="" className={'itemAvis'} loading="lazy"/>
                        <img src={reviewOneData} alt="" className={'itemAvis'} loading="lazy"/>
                    </div>

                    <div style={{display: 'flex', flexDirection: 'column', gap: 10}}>
                        <img src={reviewTwo} alt="" className={'itemAvis'} loading="lazy"/>
                        <img src={reviewTwoData} alt="" className={'itemAvis'} loading="lazy"/>
                    </div>
                </div>

                <Separator height={20}/>

                <ButtonCallToAction/>

                <Separator height={20}/>

                <div className={'subButton'}>
                    <img src={people} alt="" className={'people'} loading="lazy"/>
                    <p>{t('join')}</p>
                </div>
                <Separator height={100}/>


            </div>

    )
}
