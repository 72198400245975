import React from "react";

export default function Separator({height, width}) {

    return(
        <div style={{display:'flex', height: height, widht: width}}>

        </div>
    )
}
