import React from "react";
import './_landingPart.scss'
import floorPlan from '../../assets/floorPlan.png'
import trustPilot from '../../assets/trustPilot.png'
import waBSimple from '../../assets/waBSimple.png'
import ButtonCallToAction from "../ButtonCallToAction";
import {useTranslation} from "react-i18next";

export default function LandingPart() {
    const { t } = useTranslation();

    return(
        <div className={'landingPart containerLargeComponent'}>

            <div style={{
                display: 'flex',
                gap: 10,
                maxWidth: '90vw',
                flexWrap: 'wrap',
                alignItems: 'center',
                justifyContent: 'center'
            }}>

                <a href="https://wa.me/message/MBSVVPTMWE4UM1" style={{textDecoration: 'none'}}>
                    <div className={'newInDubai'} style={{color: '#19ba55', backgroundColor: 'rgba(37,211,102,0.1)'}}>
                        <img src={waBSimple} alt="" className={'network'}/>
                        {t('contactUsOnWhatsApp')}
                    </div>
                </a>
            </div>

            <h1 className={'landingTitle'}>{t('increaseCapital')} <span style={{color: '#FF5A5E'}}>{t('airbnb')} </span>
                {t('bookingUpTo')}</h1>

            <p className={'landingSubtitle'}> {t('inACompetitiveEnvironment')}
            </p>

            <ButtonCallToAction/>

            <img src={trustPilot} alt="" className={'trustPilot'}/>

            <img src={floorPlan} alt="" className={'floorPlan'} loading="lazy"/>

            <h2 className={'secondTitle'}>{t('heigthySeven')} <span style={{color: '#FF5A5E'}}>{t('bookYourAirbnb')}</span> {t('instantlyWith3dPlan')}</h2>

            <p className={'landingSubtitle'}>{t('soWhyNotUseIt')}</p>

            <ButtonCallToAction/>

        </div>
    )
}
