import React from 'react';
import './_lastCallToAction.scss'
import Separator from "../Separator";
import ButtonCallToAction from "../ButtonCallToAction";
import trustPilot from "../../assets/trustPilot.png";
import jules from "../../assets/jules.png";
import louka from "../../assets/louka.png";
import antoine from "../../assets/antoine.png";
import {useTranslation} from "react-i18next";

export default function LastCallToAction({width}) {
    const { t } = useTranslation();

    return(
        <div style={{backgroundColor: '#F8F8F8', borderRadius: width > 1200 && 15}}>
            <div className={'lastCallToAction containerLargeComponent'}>
                <h2 className={'titlePart'}>{t('dontMissYouOpportunity')} <span
                    style={{color: '#FF5A5E'}}>{t('increase')} </span>
                    {t('yourAirbnbBookings')}</h2>
                <p className={'subtitlePart'}>{t('lastCallToActionText')}</p>
                <img src={trustPilot} alt="" className={'trustPilot'}/>

                <Separator height={20}/>

                <ButtonCallToAction/>

                <Separator height={60}/>

                <div className={'dashedBar'}/>
                <Separator height={60}/>

                <p className={'tradeMark'}><span
                    style={{fontWeight: '200', fontSize: 30, marginRight: 10}}>OPUS</span> • 3D Rendering</p>

                <Separator height={60}/>

                <div className={'avatars'}>
                    <div className={'containerItemAvatar'}>
                        <img src={jules} alt="" className={'avatar'}/>
                        <p className={'name'}>Jules Andrieux</p>
                        <p className={'title'}>Co-Founder & MENA Director</p>
                    </div>
                    <div className={'containerItemAvatar'}>
                        <img src={louka} alt="" className={'avatar'}/>
                        <p className={'name'}>Louka Perchais</p>
                        <p className={'title'}>Client Relationship Manager</p>
                    </div>
                    <div className={'containerItemAvatar'}>
                        <img src={antoine} alt="" className={'avatar'}/>
                        <p className={'name'}>Eloi Lavalette</p>
                        <p className={'title'}>Client Relationship Manager</p>
                    </div>
                </div>

                <Separator height={60}/>

                <a style={{
                    fontSize: 11,
                    color: '#000'
                }} href="https://opusoft.notion.site/General-Terms-and-Conditions-of-Sale-13a142714147803ea0accac47f2646d5?pvs=74">
                    {t('generalTermAndConditionsOfSale')}
                </a>
                 <p className={'rights'}>OPUSOFT COMP. & SOFT. TRADING LLC, 2024, All rights reserved.</p>

            </div>
        </div>

    )
}
